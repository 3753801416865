import { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import getCookie from "../../../../utils/getCookie";
import BackendURLContext from "../../../../contexts/BackendURLContext";



function UserPending({data}){
    const {poll_id, url} = useParams();
    const keys = Object.keys(data.questions);
    const initialAns = keys.reduce((a,b)=>{a[b]= []; return a;}, {});
    const [answers, setAnswers] = useState(initialAns);
    const csrftoken = getCookie('csrftoken');
    const backend_url = useContext(BackendURLContext);
    const validate = ans => {
        for (let a in ans){
            if(ans[a].length>data.questions[a].max ||
               ans[a].length<data.questions[a].min ){
                alert(`${data.questions[a].text}\nВыберите от ${data.questions[a].min} до ${data.questions[a].max} вариантов!`);
                return false;
            }
        }
        return true;
    }
    const sendForm = e=>{
        e.preventDefault();
        if (!validate(answers)) return;
        console.log('FETCH');
        fetch(`${backend_url}/${poll_id}/${url}`, {
            method: "POST", 
            cache: "no-cache", 
            headers: {
              "Content-Type": "application/json",
              'X-CSRFToken': csrftoken,

            },
            redirect: "follow",
            referrerPolicy: "no-referrer", 
            body: JSON.stringify({
                answers
            }), 
          }).then(x => {
            if (x.status === 200)    
                window.location.reload();
            else
                alert('Ошибка');
        }).catch(console.error);
    }
    const handleInput = (e, q)=>{
        const _ans = {...answers};
        const name = e.target.name;
        const id = e.target.id;
        if (data.questions[name].type !== 'multiple'){
            _ans[name] = [id];
            setAnswers(_ans);
            return;
        }
        const isChecked = e.target.checked;
        if (!(name in _ans)){
            _ans[name] = [id];
        }
        else {
            if (isChecked){
                _ans[name].push(id);
            }
            else{
                _ans[name] = _ans[name].filter(i => i!==id);
            }
            
        }
        setAnswers(_ans);
    }
    const calcRadioType = (question) => {
        if (question.type !== 'multiple') return 'radio';
        if (question.min === 1 && question.max === 1) return 'radio';
        return 'checkbox';
    }
    return (
<>
    {data.is_voted?
        <h2>Вы проголосовали</h2>:<>
        <Form onSubmit={sendForm}>
            {Object.keys(data.questions).map((i, k)=>
            <div className='mb-3' key={k}>
                <h3 className="mb-3">{data.questions[i].text}</h3>
                {data.questions[i].type === "multiple" &&
                <p className="h5">Выберите от {data.questions[i].min} до {data.questions[i].max} вариантов</p>}

                {Object.keys(data.questions[i].choices).map((j,k)=>
                <Form.Check className="mb-3" style={{fontSize: "2em"}} key={k} type={calcRadioType(data.questions[i])} 
                required={calcRadioType(data.questions[i])==='radio'}>
                    <Form.Check.Input 
                        onChange={(e)=>handleInput(e, data.questions[i])}
                        type={calcRadioType(data.questions[i])} 
                        style={{border: "2px solid black"}}
                        name={`${i}`} id={`${i}_${j}`}/>
                    <Form.Check.Label htmlFor={`${i}_${j}`}>{data.questions[i].choices[j]}</Form.Check.Label>
                </Form.Check>)
                }
            </div>)
            }
            <Button type='submit'>Проголосовать</Button>
        </Form>
    </>}
</>
    )
}

export default UserPending;